<template>
  <div id="app">
    
    <router-view/>
  </div>
</template>
<script>
export default {
  mounted(){
    this.$store.dispatch("getUser")
    this.$store.dispatch("getProducts")
    this.$store.dispatch("getHomePageData")
    this.$store.dispatch("getServicePageData")
    this.$store.dispatch("getBatchProducts")
    this.$store.dispatch("getaboutusPageData")
    this.$store.dispatch("getcontactusPageData")
    this.$store.dispatch("getCategories")
    this.$store.dispatch("getSubCategories")
    // this.$store.dispatch()
    // this.$store.dispatch()
  }
}
</script>

<style>

</style>
