<template>
  <div>
    <div class="modal-mask" v-if="$store.state.howTo">
          <div class="modal-wrapper">
            <div class="modal-container">


              <div class="modal-body">
				<button class="btn btn-danger" style="float:right;" @click="$store.state.howTo = false;">
                    <b>&#10006;</b>
                  </button>
				 <h3>How to Order Online</h3>
				 <img src="@/assets/how to.jpg" alt="" class="d-block mx-auto">
              </div>

            </div>
          </div>
        </div>
	<div class="modal-mask" v-if="$store.state.showModal">
          <div class="modal-wrapper">
            <div class="modal-container">


              <div class="modal-body">
				<button class="btn btn-danger" style="float:right;" @click="$store.state.showModal = false;$emit('fail')">
                    <b>&#10006;</b>
                  </button>
				  
                <h1 class="ct-header" v-if="needValidation">Email Validation</h1>			
                <h1 class="ct-header" v-else-if="forgotPassword">Forgot Password</h1>			
                <h1 class="ct-header" v-else-if="!needLogin">Account Registration</h1>			
                <h1 class="ct-header" v-else>Login</h1>
					<p class="text-success" v-if="loading"> <b>Please Wait...</b> </p>
				<span class="text-danger" v-if="message.length"> <b>{{ message }}</b></span>
				<span class="text-danger" v-if="error.length"><b>{{ error }}</b></span>
                <div v-if="needValidation">
                    <div class="">
						<h1>Email Verification</h1>
						Please Provide the Verification Code received VIA email
						<input type="text" name="" placeholder="Code" v-model="verifCode" id="" class="form-control" style="margin-bottom: 5px;">
						<button class="btn btn-primary form-control" :disabled="verifCode.length !== 6" @click='verify'>Submit Verification Code</button>
						<span class="text-danger">If you did not received the email then please check spam or click <span class="link" style="cursor:pointer;" @click="resend">Resend</span> </span>
                    </div>
                </div>
				<div v-else-if="forgotPassword">
                    <div class="">
						<!-- <h1>Email Verification</h1> -->
						<div v-if="emailCodeSent">
							<p class="text-danger" > Please Provide the Confirmation Code received VIA provided email </p>
							<label for="">Verification Code</label>
							<input type="text" name="" placeholder="Verification Code" v-model="vfCode" id="" class="form-control" style="margin:5px;">
							<label for="">New Password</label>
							<input type="password" name="" placeholder="New Password" id="" v-model="newPassword" class="form-control" style="margin:5px;">
							<label for="">Confirm New Password</label>
							<input type="password" name="" placeholder="Confirm New Password" v-model="newPasswordConfirm" id="" class="form-control" style="margin:5px;">
							<button class="btn btn-primary form-control" style="margin:5px;" @click="updatePassword">Update Password </button>
						<span class="text-danger">If you did not received the email then please check spam or click 
							<span class="link" style="cursor:pointer;" @click="resendCode">Resend</span> </span>
						</div>
						<div v-else>
							<p>Please provide the Email of your registered Account</p>
							<input type="text" name="" placeholder="Email of the Account" v-model="resetEmail" id="" class="form-control" style="margin-bottom: 5px;">
						<button class="btn btn-primary form-control"  @click='sendCode'>Get Verification Code</button>
                    
						</div>
					</div>
                </div>
                <div v-else>
                    <div v-if="needLogin">
						<p>If you have an account with us, please log in.</p>
						<form id="login-form" class="form-validate form-horizontal" method="post" action="#">
							<p>Email Address <span class="star">*</span></p>
							<input class="email form-control" type="text" value="" v-model="email" required>
							<p>Password <span class="star">*</span></p>
							<input class="pasword form-control" type="password" value="" v-model="password" required>
							<div>
								<button type="submit" class="login btn btn-primary" @click.prevent="login" style="float: none; display: block;margin-top:5px;">Login</button>
                            	<b class="btn btn-link" style="float:right;margin-top:-35px;" @click="forgotPassword = true;cleareMessage();">Forgot Password?</b>
							</div>
							<div>
								<p>Don't have an account? <b class="btn btn-link" @click="needLogin = false;cleareMessage();">Create Account</b> </p>

							</div>

						</form>
					</div>
                    <div v-else>
                        <div class="">
						<h1>Create an Account</h1>
						<!-- <h4>Personal Information</h4> -->
						{{ formValid }}
						<ValidationObserver ref="simpleRules">
							<p>Business Name <span class="star">*</span></p>
							<ValidationProvider name="Company"   v-slot="{ errors }">
							<input type="text" v-model="company" class="form-control" style="margin-bottom:3px;">
							<!-- <span class="text-danger">{{ errors[0] }}</span> -->
							</ValidationProvider>

							<p>Customer First name <span class="star">*</span></p>
							<ValidationProvider name="First Name"  rules="names" v-slot="{ errors }">
							<input type="text" v-model="firstName" class="form-control" style="margin-bottom:3px;">
							<span class="text-danger">{{ errors[0] }}</span>
							</ValidationProvider>
							
							<p>Customer Last name <span class="star">*</span></p>
							<ValidationProvider name="Last Name"  rules="names" v-slot="{ errors }">
							<input type="text" v-model="lastName" class="form-control" style="margin-bottom:3px;">
							<span class="text-danger">{{ errors[0] }}</span>
							</ValidationProvider>

							<p>Job Title <span class="star">*</span></p>
							<input type="text" v-model="titleName" class="form-control" style="margin-bottom:3px;">

							<p>Username <span class="star">*</span></p>
							<ValidationProvider name="Username"  rules="names" v-slot="{ errors }">
							<input type="text" v-model="username" class="form-control" style="margin-bottom:3px;">
							<span class="text-danger">{{ errors[0] }}</span>
							</ValidationProvider>
							<p>Email Address <span class="star">*</span></p>
							<ValidationProvider name="Email"  rules="required|email" v-slot="{ errors }">
							<input type="email"  v-model="email" class="form-control" style="margin-bottom:3px;">
							<span class="text-danger">{{ errors[0] }}</span>
							</ValidationProvider>
							<p>Password <span class="star">*</span></p>
							<ValidationProvider name="Password"  rules="password" v-slot="{ errors }">
							<input type="password"  v-model="password" class="form-control" style="margin-bottom:3px;">
							<span class="text-danger" v-if="password">{{ errors[0] }}</span><br>
							<span class="text-danger" v-if="password.length && password !== confirmation">Password Must Be Confirmed</span>
							</ValidationProvider>
							<p>Confirm Password <span class="star">*</span></p>
							<ValidationProvider name="Password"  v-slot="{ errors }" vid="confirmation">
							<input type="password" class="form-control"  v-model="confirmation" style="margin-bottom:3px;">
							<span class="text-danger">{{ errors[0] }}</span>
							</ValidationProvider>
							<p>Referral Code <span class="star">(Optional)</span></p>
							<input type="text" v-model="refCode" class="form-control" style="margin-bottom:3px;">
							<!-- <p>Email Address <span class="star">*</span></p>
							<ValidationProvider name="email" rules="required|email" v-slot="{ errors }">
							<input v-model="email" type="text" style="margin-bottom:3px;">
							<span class="text-danger">{{ errors[0] }}</span>
							</ValidationProvider> -->
							<button @click="register" class="btn btn-primary" style="margin-top:5px;color:#FFFF;">
							<span style="color:#FFFF;" v-if="loading">Loading...</span>
							<span style="color:#FFFF;" v-else>Register</span>
							</button>
							<!-- <span class="text-danger" v-if="message.length">{{ message }}</span> -->
                            <p>Already have an account? <b class="btn btn-link" @click="needLogin = true;cleareMessage();"> Login</b> </p>

						</ValidationObserver>
						
					</div>
                    </div>
                </div>
				<div>
					<div class="signup-buttons">
        <a href="#" class="google-signup" @click.prevent="loginWithGoogle" style="
		    text-align: center;
    display: block;
    background: #4285F4;
    color: #FFF;
    padding: 8px 1px;
		">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" aria-hidden="true"><title>Google</title><g fill="none" fill-rule="evenodd"><path fill="#4285F4" d="M17.64 9.2045c0-.6381-.0573-1.2518-.1636-1.8409H9v3.4814h4.8436c-.2086 1.125-.8427 2.0782-1.7959 2.7164v2.2581h2.9087c1.7018-1.5668 2.6836-3.874 2.6836-6.615z"></path><path fill="#34A853" d="M9 18c2.43 0 4.4673-.806 5.9564-2.1805l-2.9087-2.2581c-.8059.54-1.8368.859-3.0477.859-2.344 0-4.3282-1.5831-5.036-3.7104H.9574v2.3318C2.4382 15.9832 5.4818 18 9 18z"></path><path fill="#FBBC05" d="M3.964 10.71c-.18-.54-.2822-1.1168-.2822-1.71s.1023-1.17.2823-1.71V4.9582H.9573A8.9965 8.9965 0 0 0 0 9c0 1.4523.3477 2.8268.9573 4.0418L3.964 10.71z"></path><path fill="#EA4335" d="M9 3.5795c1.3214 0 2.5077.4541 3.4405 1.346l2.5813-2.5814C13.4632.8918 11.426 0 9 0 5.4818 0 2.4382 2.0168.9573 4.9582L3.964 7.29C4.6718 5.1627 6.6559 3.5795 9 3.5795z"></path></g></svg>
            Signup Using Google
        </a>
    </div>
				</div>
					
              </div>

            </div>
          </div>
        </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { extend } from 'vee-validate';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, email, min, length } from 'vee-validate/dist/rules';

extend('names', value => {
  if (value.length > 3) {
    return true;
  }

  return 'This {_field_} field must be at least 3 characters long';
});
extend('password', value => {
  if ( value.length > 3 ) {
    return true;
  }

  return 'Password must be at least 5 characters long and must be confirmed';
});

extend('email', email);
extend('required', {
  ...required,
  message: 'This field is required'
});
export default {
    data(){
        return {
			googleSignInParams: {
        client_id: '854879043010-ghpc9bbj2usok37bv9jlm2bkmrn86t98.apps.googleusercontent.com'
      },
		mailSent:false,
		min,
		newPasswordConfirm:"",
		newPassword:"",
		refCode:"",
		vfCode:"",
        needValidation:false,
        emailCodeSent:false,
		verifCode:'',
		length,
		email:'',
		forgotPassword:false,
		username:'',
		firstName:'',
		company:'',
		lastName:'',
		titleName:'',
		password:'',
		confirmation:'',
		formValid:'',
		message:"",
        loading: false,
		message:'',
		verifCode:'',
        tempToken:'',
        tempUser:'',
        needLogin:true,resetEmail:"",
        error:"",
		password:''
        }
    },
    components:{ ValidationProvider, ValidationObserver},
    
    methods:{
		handleReferral(id){
			Swal.fire({
			title: 'Enter Referral Code if you have any or Skip',
			input: 'text',
			inputAttributes: {
				autocapitalize: 'off'
			},
			showCancelButton: true,
			confirmButtonText: 'Submit',
			cancelButtonText: 'Skip',
			showLoaderOnConfirm: true,
			preConfirm: (referral) => {
				return this.$store.dispatch("setReferral",{id, referral})
				.then(response => {
					if (!response.status) {
					throw new Error(response.message)
					}
					return response
				})
				.catch(error => {
					Swal.showValidationMessage(
					`Request failed: ${error}`
					)
				})
			},
			allowOutsideClick: () => !Swal.isLoading()
			}).then((result) => {
			if (result.isConfirmed) {
				alert("referral set")
			}
			})
		},
		loginWithGoogle () {
      this.$gAuth
        .signIn()
        .then(async(GoogleUser) => {
          var userInfo = {
            
                name: GoogleUser.getBasicProfile().getName(),
				id:GoogleUser.getId(),
                email: GoogleUser.getBasicProfile().getEmail(),
                profileImage: GoogleUser.getBasicProfile().getImageUrl()
            
          }
		  let dec = await this.$store.dispatch("loginGoogleUser", userInfo)
		  if (dec.status) {
			localStorage.setItem('VestigoUser', dec.id)
				localStorage.setItem('VestigoUserToken', dec.token)
                this.$store.dispatch('getUser')
                this.$store.state.showModal = false;	
				this.$emit("success")
				if(dec.isNewUser){
					//check if user provides the referral code
					alert("we found a new user here")
					this.handleReferral(dec.id)
				}
		  }else{
			alert("error in Auth process")
		  }
		  
		  console.log(userInfo, dec)
        //   this.$store.commit('setLoginUser', userInfo)
        //   router.push('/home')
        })
        .catch(error => {
          console.log('error', error)
        })
    },
		async updatePassword(){
			this.loading = true;
			this.cleareMessage();
			let errors = [];
			if(this.vfCode.length !== 6) errors.push("Verification Code Length Must Be 6 Characters") 
			if(this.newPassword.length < 6) errors.push("Password length Must Be Minimum 6 Characters") 
			if(this.newPassword !== this.newPasswordConfirm) errors.push("Password Must Be Confirmed") 
			if (errors.length) {
				this.error = errors[0];
				this.loading = false
			}else{
				let dec = await this.$store.dispatch("changePassword", {email: this.resetEmail, code: this.vfCode, password : this.newPassword})
				console.log("dec passwordrest", dec)
				if (dec.status) {
				localStorage.setItem('VestigoUser', dec.user._id)
				localStorage.setItem('VestigoUserToken', dec.token)
				this.$store.dispatch("getUser")
				this.cleareModal()
				}else{
					this.error = dec.message;
					this.loading = false
				}
			}
		},
		cleareModal(){
			console.log("clearing Modal");
			this.$store.state.showModal = false;
			this.$emit("success")

		},
		async sendCode(){
			this.loading = true;
			this.cleareMessage();
			let email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.resetEmail)
			if (!email) {
				this.error = "Please provide Valid email";
				this.loading = false
			}else{
				let dec = await this.$store.dispatch("sendPasswordResetCode",{email: this.resetEmail})
				if (dec.status) {
					this.message = dec.message;
					this.loading = false;
					this.emailCodeSent = true;
				}else{
					this.message = dec.message;
					this.loading = false;
				}
			}
		},
		async resendCode(){
			this.loading = true;
			this.cleareMessage();
			let email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.resetEmail)
			if (!email) {
				this.error = "Please provide Valid email";
				this.loading = false
			}else{
				let dec = await this.$store.dispatch("sendPasswordResetCode",{email: this.resetEmail})
				if (dec.status) {
					this.message = "Password Reset Verification Has Been Re-Sent To your Provided Email."
					this.loading = false
				}
			}
		},
		cleareMessage(){
			this.error = '';
			this.message = '';
		},
        async verify(){
		this.loading = true
		this.message = '';
		let dec = await this.$store.dispatch("checkVerifCode", {code: this.verifCode, email: this.email, id: this.code})
		if (dec.status) {
            localStorage.setItem('VestigoUser', this.tempUser)
				localStorage.setItem('VestigoUserToken', this.tempToken)
                this.$store.dispatch('getUser')
                this.loading = false
                this.$store.state.showModal = false;
				this.$emit("success")

			//activate user
		}else{
			this.message = dec.message;
			this.loading = false;
		}
	},
	async resend(){
		this.message = '';
		this.loading = true
		let dec = await this.$store.dispatch("sendVerifCode", {email: this.email})
		if (dec.status) {
		this.loading = false
			this.message = "Verification Code Has Been Sent"
		}else{
		this.loading = false
			this.message = "Failed to Send Verification code, please try again later or Contact Support"
		}
	},
        async login(){
            this.loading = true;
		this.error = ""
		// var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
		let email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)
		let password = (this.password.length > 4) ? true : false;
		if (!email) this.error = "Please provide Valid email"
		if (!password) this.error = "Password must be at least 5 Characters"
		if (this.error.length) {
			console.log("val failed")
            this.loading = false
		}else{
		let dec = await this.$store.dispatch("login", {email: this.email, password: this.password}) 
		if (dec.status) {
			if (dec.user.isVerified) {
				localStorage.setItem('VestigoUser', dec.user._id)
				localStorage.setItem('VestigoUserToken', dec.token)
                this.$store.dispatch('getUser')
                this.$store.state.showModal = false;
				this.$emit("success")
				// location.reload()	
			}else{
				// location.replace('/verify/'+dec.user.email+'/'+dec.user._id)
                this.tempUser = dec.user._id
				this.tempToken = dec.token
                this.needValidation = true
                this.loading = false;
				
			}
			
		}else{
			this.error = dec.message
			this.loading = false
		}
		}
		// console.log(email)
		// let dec = await this.$store.dispatch("login", {email: this.email, password: this.password}) 
	},
        register(){
			this.error = '';
			this.message = '';
		this.$refs.simpleRules.validate().then(async(success) => {
			if (success) {
				if (this.password == this.confirmation) {
					this.loading = true
					let user = {
						password:this.password,
						name: this.firstName,
						company: this.company,
						accountTitle: this.company,
						lastName: this.lastName,
						titleName: this.titleName,
						referralCode: this.refCode,
						username: this.username,
						email: this.email,
						isSuperUser: false
					}
					let dec = await this.$store.dispatch("registerUser", user)
					if (dec.status) {
						this.needValidation = true
                        this.tempUser = dec.id
				        this.tempToken = dec.token
					this.loading = false

					}else{
						this.error = dec.message;
						this.loading = false
					}
				}
			}
		})
		
	},
    },

}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 999899989998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 385px;
  margin-left: auto;
    margin-right: auto;
  /* margin: 0px auto; */
  padding: 1px 0px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 0px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.g-signin-button {
  /* This is where you control how the button looks. Be creative! */
  display: inline-block;
  padding: 4px 8px;
  border-radius: 3px;
  background-color: #3c82f7;
  color: #fff;
  box-shadow: 0 3px 0 #0f69ff;
}
</style>