import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // URL: "http://localhost:3030",
    URL:"https://vestigoprint.ie",
    products: [],
    categories: [],
    servicesData: null,
    homePageData: null,
    howTo: false,
    sliderImages: [],
    mainProduct: null,
    mainSingleProduct: null,
    jobId: null,
    mainProducts: [],
    mainProductsAll: [],
    subProducts: [],
    teamImages: [],
    navMenu: false,
    contactusPageData: null,
    aboutusPageData: null,
    userPCP: null,
    branchSwitch: null,
    branchSwitchAddress: null,
    defaultBranchSwitchAddress: null,
    branchSwitchYes: null,
    product: null,
    user: null,
    userAddresses: null,
    userCart: null,
    userCartItems: [],
    userOrders: [],
    userQuotes: [],
    order: null,
    orderActivs: [],
    cartItem: null,
    orderItemUpdates: [],
    uploadProgress: 0,
    services: [],
    subquotes: [],
    refs: [],
    subCategories: [],
    refsUserData: [],
    otherProducts: [


    ],
    products: [


    ],
    homeSlider: [

    ],
    aboutUs: {
      team: [

      ],
      intro: ""
    },
    contactUs: {

    },
    showModal: false,
  },
  mutations: {
  },
  actions: {
    updateUserInfo: async (context, data) => {
      return await axios.post(context.state.URL + '/api/update/user-info', data)
        .then((res) => { return res.data })
        .catch((error) => { console.log(error); return { status: 0 } })
    },
    updateAddress: async (context, data) => {
      return await axios.post(context.state.URL + '/api/update/address', data)
        .then((res) => { context.state.userAddresses = res.data.addresses; return res.data })
        .catch((res) => { return { status: 0 } })
    },
    setReferral: async (context, data) => {
      return await axios.post(context.state.URL + '/api/set/referral', data)
        .then((res) => { return res.data })
        .catch((error) => { return { status: 0, message: "Internal Server Error" } })
    },
    getMainProductById: async (context, data) => {
      return await axios.get(context.state.URL + '/api/get/products-and-subproducts/' + data.id)
        .then((res) => { context.state.mainProduct = res.data.main; context.state.subProducts = res.data.sub })
    },
    getMainSingleProductById: async (context, data) => {
      return await axios.get(context.state.URL + '/api/get/single-products/' + data.id)
        .then((res) => { context.state.mainSingleProduct = res.data.single; })
    },
    getJobById: async (context, data) => {
      try {
        const response = await axios.get(context.state.URL + '/api/get/job/' + data.id);
        context.commit('setJobId', response.data.job);
        return response.data.job; // Return the job data if needed
      } catch (error) {
        console.error("Error fetching job by ID:", error);
        throw error; // Propagate the error if needed
      }
    },
    getReorderProductById: async (context, data) => {
      return await axios.get(context.state.URL + '/api/get/products-reorder-subproducts/' + data.id)
        .then((res) => { context.state.mainProduct = res.data.main; context.state.subProducts = res.data.sub; context.state.reorderProduct = res.data.product })
    },
    updateArtowrkSamples: async (context, data) => {
      return await axios.post(context.state.URL + '/api/update/artwork-samples', data)
        .then((res) => { return res.data })
        .catch((error) => { return { status: 0 } })
    },
    loginGoogleUser: async (context, data) => {
      return await axios.post(context.state.URL + '/api/google-login', data)
        .then((res) => { return res.data })
    },
    sendPasswordResetCode: async (context, data) => {
      return await axios.post(context.state.URL + '/api/send-password-reset-code', data)
        .then((res) => { return res.data; })
    },
    changePassword: async (context, data) => {
      return await axios.post(context.state.URL + '/api/reset-password', data)
        .then((res) => { return res.data; })
    },
    resetPassword: async (context, data) => {
      return await axios.post(context.state.URL + '/api/change-password', data)
        .then((res) => { return res.data; })
    },
    updateOrderEmail: async (context, data) => {
      return await axios.post(context.state.URL + '/api/update/order-email', data)
        .then((res) => { return res.data })
        .catch((error) => { return { status: 0 } })
    },
    orderNow: async (context, order) => {
      return await axios.post(context.state.URL + '/api/order-now', order)
        .then((res) => { return res.data })
        .catch((error) => { return { status: 0 } })
    },
    updateQuoteItem: async (context, data) => {
      axios.post(context.state.URL + '/api/update/quote-item', data)
    },
    getUserQuotes: async (context) => {
      let user = localStorage.getItem('VestigoUser')
      if (user) {
        axios.get(context.state.URL + '/api/get/user-quotes/' + user)
          .then((res) => {
            context.state.userQuotes = res.data.quotes;
          })
      }
    },
    getQuotesQuantity: async (context, data) => {
      console.log(data, "data.iddata.id");
      axios.get(context.state.URL + '/api/get/quotesQuantity/' + data)
        .then((res) => {
          context.state.subquotes = res.data.subquotes;
        })
    },
    getServices: async (context) => {
      axios.get(context.state.URL + "/api/get/services")
        .then((res) => { context.state.services = res.data.services })
    },
    acceptOrder: async (context, data) => {
      return await axios.post(context.state.URL + '/api/accept/order', data)
        .then((res) => { return res.data })
    },
    markOrderComplete: async (context, data) => {
      return await axios.post(context.state.URL + '/api/mark/order-complete', data)
    },
    updateArtwork: async (context, data) => {
      return await axios.post(context.state.URL + '/api/update/artwork', data)
        .then((res) => { return res.data })
    },
    addOrderUpdate: async (context, data) => {
      return await axios.post(context.state.URL + '/api/add/order-update', data)
        .then((res) => {
          context.state.orderItemUpdates = res.data.orderItemUpdates
          return res.data
        })
    },
    verifySupplier: async (context, data) => {
      return await axios.post(context.state.URL + '/api/verify/supplier', data)
        .then((res) => { return res.data })
    },
    getCartItem: async (context, data) => {
      axios.post(context.state.URL + '/api/get/supplier-cart-item', data)
        .then((res) => {
          context.state.cartItem = res.data.item
          context.state.order = res.data.order
          context.state.orderItemUpdates = res.data.orderItemUpdates
        })
    },
    getRefs: async (context) => {
      axios.get(context.state.URL + "/api/get/users/all/refs")
        .then((res) => { context.state.refs = res.data.data })
    },
    getOrderActiv: async (context, data) => {
      console.log('in open view store call')
      axios.get(context.state.URL + '/api/get/order-activs/' + data.order)
        .then((res) => { context.state.orderActivs = res.data.logs })
    },
    placeOrder: async (context, data) => {
      return await axios.post(context.state.URL + '/api/create/order', data)
        .then((res) => { return res.data })
    },
    checkVerifCode: async (context, data) => {
      return await axios.post(context.state.URL + '/api/check/verif-code', data)
        .then((res) => { return res.data })
    },
    sendVerifCode: async (context, data) => {
      return await axios.post(context.state.URL + '/api/send/verif-code', data)
        .then((res) => { return res.data })
    },
    registerUser: async (context, data) => {
      return await axios.post(context.state.URL + '/api/register', data)
        .then((res) => { return res.data })
    },
    addNewEmail: async (context, data) => {
      return await axios.post(context.state.URL + '/api/add/new-order-email', data)
        .then((res) => { return res.data })
        .catch((error) => { return { status: 0 } })
    },
    getUser: async (context) => {
      let user = localStorage.getItem('VestigoUser')
      if (user) {
        axios.get(context.state.URL + '/api/get/user/' + user)
          .then((res) => {
            if (res.data.user) {
              context.state.user = res.data.user;
              context.state.userOrders = res.data.userOrders;
              context.state.orderActivs = res.data.oderAllActivity;
              context.state.userAddresses = res.data.userAddresses;
              context.state.userCart = res.data.userCart;
              context.state.userCartItems = res.data.userCartItems;
              context.state.subChildUser = res.data.subChildUser;
              return
            } else {
              localStorage.removeItem("VestigoUser")
              localStorage.removeItem("VestigoUserToken")
              // location.replace('/login')
              return
            }
          })
      } else {
        return;
      }
    },
    updateCartItems: async (context, data) => {
      return await axios.post(context.state.URL + '/api/update/cart-items', data)
        .then((res) => { return res.data })
    },
    removeCartItem: async (context, data) => {
      return await axios.post(context.state.URL + '/api/remove/cart-item', data)
        .then((res) => { return res.data })
    },
    logout: (context) => {
      localStorage.removeItem("VestigoUser")
      localStorage.removeItem("VestigoUserToken")
      location.reload()
    },
    resellerLogout: (context) => {
      alert("hey")
      this.$router.push({path: '/user-dashboard'})
    },
    updateArtowrkData: async (context, data) => {
      axios.post(context.state.URL + '/api/update/artwork-data', data)
    },
    uploadFilesArtworksAttach: async (context, formData) => {
      const config = {
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          console.log('percentCompleted', percentCompleted)
        }
      }
      return await axios.post(context.state.URL + '/api/upload/artwork-attachments', formData, {
        onUploadProgress: progressEvent => {
          console.log(progressEvent, "progressEvent")
          const total = progressEvent.total
          const current = progressEvent.loaded

          let percentCompleted = Math.floor(current / total * 100)
          console.log('completed: ', percentCompleted)
          context.state.uploadProgress = percentCompleted
        }
      })
        .then((res) => { return res.data })
    },
    uploadFilesArtworks: async (context, formData) => {
      const config = {
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          console.log('percentCompleted', percentCompleted)
        }
      }
      return await axios.post(context.state.URL + '/api/upload/artwork', formData, {
        onUploadProgress: progressEvent => {
          console.log(progressEvent, "progressEvent")
          const total = progressEvent.total
          const current = progressEvent.loaded

          let percentCompleted = Math.floor(current / total * 100)
          console.log('completed: ', percentCompleted)
          context.state.uploadProgress = percentCompleted
        }
      })
        .then((res) => { return res.data })
    },
    login: async (context, data) => {
     
      if( data.hasOwnProperty("rsChk") ) {
        let user = localStorage.getItem('VestigoUser')
        data["masterUserId"] = user;
      }
      return await axios.post(context.state.URL + '/api/login', data)
        .then((res) => {
          return res.data
        })
        .catch((error) => { return { status: 0, error } })
    },
    refsLogin: async (context, data) => {
      return await axios.post(context.state.URL + '/api/refs/login', data)
        .then((res) => {
          return res.data
        })
        .catch((error) => { return { status: 0, error } })
    },
    refsUserDataAPI: async (context, data) => {
      let check = localStorage.getItem("globalAccUser");
      if(check){
        return await axios.get(context.state.URL + '/api/get/getRefs/'+ check)
          .then((res) => {
            context.state.refsUserData = res.data
            return res.data
          })
          .catch((error) => { return { status: 0, error } })
        }else{
          return { status: 0, data:null } 
        }
    },
    addCartItem: async (context, data) => {
      return await axios.post(context.state.URL + '/api/add/cart-item', data)
        .then((res) => { return res.data })
    },
    setDefaultAddress: async (context, data) => {
      return await axios.post(context.state.URL + "/api/update/default-address", data)
        .then((res) => { return res.data })
    },
    setDefaultBillingAddress: async (context, data) => {
      return await axios.post(context.state.URL + "/api/update/default-billing-address", data)
        .then((res) => { return res.data })
    },
    getPcps: async (context) => {
      let user = localStorage.getItem('VestigoUser')
      axios.get(context.state.URL + "/api/get/pcp/" + user)
        .then((res) => { context.state.userPCP = res.data.pcps })
    },
    addAddress: async (context, data) => {
      return await axios.post(context.state.URL + '/api/add/address', data)
        .then((res) => { return res.data })
    },
    deleteAddress: async (context, data) => {
      return await axios.post(context.state.URL + '/api/delete/address/' + data)
        .then((res) => { return res.data })
    },
    addUser: async (context, data) => {
      return await axios.post(context.state.URL + '/api/add/addUser', data)
        .then((res) => { return res.data })
    },

    createQuote: async (context, data) => {
      return await axios.post(context.state.URL + '/api/create/quote', data)
        .then((res) => { return res.data })
    },
    branchSwitchYes: async (context, data) => {
      let user = localStorage.getItem('VestigoUser')
      if (user) {
        axios.get(context.state.URL + "/api/get/branchSwitchYes/" + user)
          .then((res) => {
            if (res.data.user) {
              context.state.branchSwitchYes = res.data.user;
              return
            } else {
              localStorage.removeItem("VestigoUser")
              localStorage.removeItem("VestigoUserToken")
              // location.replace('/login')
              return
            }
          })
      } else {
        return;
      }

    },
    branchSwitch: async (context, data) => {
      axios.get(context.state.URL + "/api/get/branchSwitch/" + data.slug)
        .then((res) => { context.state.branchSwitch = res.data.user })
    },
    branchSwitchAddress: async (context, data) => {
      axios.get(context.state.URL + "/api/get/branchSwitchAddress/" + data.slug)
        .then((res) => { context.state.branchSwitchAddress = res.data.user })
    },
    defaultBranchSwitchAddress: async (context, data) => {
      axios.get(context.state.URL + "/api/get/defaultBranchSwitchAddress/" + data.slug)
        .then((res) => { context.state.defaultBranchSwitchAddress = res.data.user })
    },
    getProducts: async (context) => {
      axios.get(context.state.URL + "/api/get/products/all")
        .then((res) => {
          context.state.products = res.data.data
          context.state.mainProducts = res.data.main
          context.state.mainProductsAll = res.data.mainAll
        })
    },

    getProductBySlug: async (context, data) => {
      axios.get(context.state.URL + "/api/get/product-slug/" + data.slug)
        .then((res) => { context.state.product = res.data.data })
    },
    getProductsByCategory: async (context, data) => {
      axios.get(context.state.URL + "/api/get/products-category/" + data.category)
        .then((res) => { context.state.mainProducts = res.data.data })
    },

    getCategories: async (context) => {
      axios.get(context.state.URL + "/api/get/categories")
        .then((res) => { context.state.categories = res.data.categories })
    },
    getSubCategories: async (context) => {
      axios.get(context.state.URL + "/api/get/sub-categories")
        .then((res) => { context.state.subCategories = res.data.categories })
    },

    getHomePageData: async (context) => {
      axios.get(context.state.URL + "/api/get/page-data/home")
        .then((res) => {
          console.log("response of homepagedata", res.data)
          context.state.homePageData = res.data.data
          context.state.homeSlider = res.data.data.data.homeSlider
        })
    },
    getServicePageData: async (context) => {
      axios.get(context.state.URL + "/api/get/page-data/services")
        .then((res) => {
          // console.log(res.data.data.data);
          // return
          context.state.servicesData = res.data.data;
          context.dispatch("getProduct", { product: res.data.data.data.largeItem })

        })
    },
    getProduct: async (context, data) => {
      if (data.product) {
        return await axios.get(context.state.URL + "/api/get/product/" + data.product)
          .then((res) => { context.state.product = res.data.data })
      }

    },

    getBatchProducts: async (context, data) => {
      return await axios.get(context.state.URL + '/api/get/products/batch')
        .then((res) => { context.state.otherProducts = res.data.products })
    },
    getaboutusPageData: async (context) => {
      axios.get(context.state.URL + "/api/get/page-data/aboutus")
        .then((res) => {
          // console.log(res.data.data)
          context.state.aboutusPageData = res.data.data
          context.state.aboutUs.team = res.data.data.data.team
          context.state.aboutUs.intro = res.data.data.data.intro
          context.state.aboutUs.clients = res.data.data.data.clients
          context.state.aboutUs.tests = res.data.data.data.tests
        })
    },
    getcontactusPageData: async (context) => {
      axios.get(context.state.URL + "/api/get/page-data/contactus")
        .then((res) => {
          // console.log(res.data.data)
          context.state.contactusPageData = res.data.data.data
          context.state.contactUs = res.data.data.data
        })
    },
    checkPromo: async (context, data) => {
      return await axios.get(context.state.URL + '/api/check/promo-code/' + data.code)
        .then((res) => { return res.data })
    }
  },
  modules: {
  }
})
