<template>
  <div class="home" v-if="ready">
    <nav-bar-home />
	<!-- <m-menu></m-menu> -->
	<!--Main index : Begin-->
	<main class="main index" >
	
		<!--Home slider : Begin-->
		<section class="home-slidershow">
			<carousel :autoplayTimeout="5000" :per-page="1"  :mouse-drag="true" :autoplay="true" :loop='true' :autoplayHoverPause="true" :paginationEnabled='false' :speed="500" :centerMode="true">
    <slide v-for="slide in homeSlider" :key="slide.id">
		<div class="visible-md visible-lg">
			<div :style="{'color':'red','background-image':'url('+$store.state.URL+'/uploads/slider/bg-'+slide.image+')'}" class="ttttt" style="">
			<div class="slide-cont" v-if="slide.txDirec == 'tp-caption lft'">
			<img height="400" :src="$store.state.URL+'/uploads/slider/'+slide.image" alt="" class="animate__animated" :class="[items[Math.floor(Math.random()*items.length)]]" />
			<div class="slide-right-cont animate__animated" :class="[items[Math.floor(Math.random()*items.length)]]">
				<h2 class="slider-title-right">{{ slide.title }}</h2>
				<h4 class="slider-sub-right" v-html="slide.desc"></h4>	
							<a class="btn btn-danger" href="#">VIEW MORE</a>
				
			</div>		
		</div>
		<div class="slide-cont" v-else>
			<img height="400" :src="$store.state.URL+'/uploads/slider/'+slide.image" alt="" class="animate__animated"  :class="[items[Math.floor(Math.random()*items.length)]]"/>
			<div class="slide-left-cont animate__animated" :class="[items[Math.floor(Math.random()*items.length)]]">
				<h2 class="slider-title-left">{{ slide.title }}</h2>
				<h4 class="slider-sub-left" v-html="slide.desc"></h4>	
							<a class="btn btn-danger" href="#">VIEW MORE</a>
				

			</div>		
		</div>
		</div>
		</div>
		<div class="visible-xs visible-sm hidden-md">
			<div :style="{'color':'red','background-image':'url('+$store.state.URL+'/uploads/slider/bg-'+slide.image+')'}" class="tttttSmall" style="">
			<div class="slide-cont" >
			<img  style="width:300px;display: block; margin-left: auto; margin-right: auto;" :src="$store.state.URL+'/uploads/slider/'+slide.image" alt="" class="animate__animated" :class="[items[Math.floor(Math.random()*items.length)]]" />
			<div class="animate__animated smallSlider" :class="[items[Math.floor(Math.random()*items.length)]]">
				<h2 class="slider-title-right">{{ slide.title }}</h2>
				<h4 class="slider-sub-right" v-html="slide.desc"></h4>	
							<a class="btn btn-danger" href="#">VIEW MORE</a>
				
			</div>		
		</div>
	
		</div>
		</div>
		
		
		
    </slide>
    <!-- <slide>
      Slide 2 Content
    </slide> -->
  </carousel>
		</section>
		<section>
			<div class="container">
				<div class="row">
				<div class="col-lg-4 col-md-4 col-sm-3 col-xs-12" style="padding:0px;margin:0px;">
					<img src="images/testimonials/boxed10-bn1.jpg" alt="" style="border:1px solid gray;">
				</div>
				<div class="col-lg-4 col-md-4 col-sm-3 col-xs-12" style="padding:0px;margin:0px;">
					<img src="images/testimonials/quotes_slider.png" alt="" style="border:1px solid gray;">					
				</div>
				<div class="col-lg-4 col-md-4 col-sm-3 col-xs-12" style="padding:0px;margin:0px;">
					<img src="images/testimonials/boxed10-bn3.jpg" alt="" style="border:1px solid gray;">
				</div>	

			</div>
			</div>
		</section>
		<!--Home Category : Begin-->
		<section class="home-category">
			<div class="container">
				<div class="row"> 
					<div class="col-lg-4 col-md-4 col-sm-3 col-xs-12 block block-left">
						<a href="#" class="image">
							<img :src="$store.state.URL+'/uploads/others/vr-one.jpg'" alt="banner-category"/>
						</a>
						<div class="info">
							<a href="#">CANVAS PRINT</a>
						</div>
					</div>
					<div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 block block-center" >
						<div class="inner-top">
							<div class="">
								<a href="#" class="">
									<img :src="$store.state.URL+'/uploads/others/hr-one.jpg'" alt="banner-category"/>
								</a>
								<div class="info">
									<a href="#">BROCHURE</a>
								</div>
							</div>
						</div>
						<div class="inner-bottom">
							<a href="#" class="image">
								<img :src="$store.state.URL+'/uploads/others/hr-two.jpg'" alt="banner-category"/>
							</a>
							<div class="info">
								<a href="#">LOGO PRINT</a>
							</div>
						</div>
					</div>
					<div class="col-lg-4 col-md-4 col-sm-3 col-xs-12 block block-right">
						<div class="">
							<a href="#" class="image">
								<img :src="$store.state.URL+'/uploads/others/vr-two.jpg'" alt="banner-category"/>
							</a>
							<div class="info">
								<a href="#">ENVELOPE</a>
							</div>
						</div>
						
					</div> 
				</div>
			</div>
		</section>
		 <section class="home-promotion-product home-product parten-bg">
			<div class="container">
				<div class="row">
					<div class="block-title-w">
						<h2 class="block-title">Promotions Products</h2> 
						<span class="icon-title">
							<span></span>
							<i class="fa fa-star"></i>
						</span>
					</div>
					<ul class="slider-w slider-owl">
						<li class="pro-item" v-for="pro in mainProducts" :key="pro.id" style="max-width:25%;display:inline-block;">
							<div class="product-image-action">
								<img :src="$store.state.URL+'/uploads/product/'+pro.image" :alt="pro.title">
								<div class="action">
									<!-- <button type="button" data-toggle="tooltip" data-placement="top" class="add-to-cart gbtn" title="Add to cart">
										<i class="fa fa-shopping-cart"></i> 
									</button>
									<a href="#" data-toggle="tooltip" data-placement="top" title="Wishlist" class="add-to-wishlist">
										<i class="fa fa-heart"></i>
									</a>
									<a href="#" data-toggle="tooltip" data-placement="top" title="compare" class="add-to-compare">
										<i class="fa fa-refresh"></i> 
									</a> 
									<a href="#" data-toggle="tooltip" data-placement="top" title="Quickview" class="quick-view">
										<i class="fa fa-eye"></i>
									</a> -->
								</div>
								<span class="product-icon sale-icon" v-if="pro.isSale">sale!</span>
								<span class="product-icon new-icon" v-if="pro.isNew">new!</span>

							</div>
							<div class="product-info">
								<a :href="'/custom-product/'+pro._id" title="product" class="product-name">{{pro.productName}}</a> 
								<div class="price-box" >
									<!-- <span class="special-price"> &euro; {{Number(pro.quantityGroups[0].sellingProductPrice).toFixed(2)}}</span> -->
									<!-- <span class="special-price">$ {{ (Number(pro.price) - Number(pro.discount)).toFixed(2) }}</span>  -->
								</div>
								<!-- <div class="price-box" v-else>
									<span class="old-price">$ {{pro.sellingProductPrice}}</span>
									
								</div> -->
								<div class="rating-box">  
									<i class="fa fa-star"></i>
									<i class="fa fa-star"></i>
								  	<i class="fa fa-star-half-o"></i>
								  	<i class="fa fa-star-o"></i>
								  	<i class="fa fa-star-o"></i>
								</div>
							</div>
						</li>
						
					</ul>
				</div>
			</div>
		</section>
		<section>
			<div class="container">
				<div class="row">
				<div class="col-lg-4 col-md-4 col-sm-3 col-xs-12" style="padding:0px;margin:0px;">
					<img src="images/testimonials/boxed09-bn-11.jpg" alt="" style="width:100%;">
				</div>
				<div class="col-lg-4 col-md-4 col-sm-3 col-xs-12" style="padding:0px;margin:0px;">
					<img src="images/testimonials/boxed09-bn-12.jpg" alt="" style="width:100%;">
				</div>
				<div class="col-lg-4 col-md-4 col-sm-3 col-xs-12" style="padding:0px;margin:0px;">
					<img src="images/testimonials/boxed09-bn-13.jpg" alt="" style="width:100%;">
				</div>	

			</div>
			</div>
		</section>
		<section>
			<div class="container">
				<div class="row">
				<div class="col-lg-2 col-sm-4 col-xs-12" style="padding:0px;margin:0px;">
					<img src="images/testimonials/1-1-150x115.png" alt="" style="border:1px solid gray;width:190px;">
				</div>
				<div class="col-lg-2 col-sm-4 col-xs-12" style="padding:0px;margin:0px;">
					<img src="images/testimonials/3-2-150x115.png" alt="" style="border:1px solid gray;width:190px;">
				</div>
				<div class="col-lg-2 col-sm-4 col-xs-12" style="padding:0px;margin:0px;">
					<img src="images/testimonials/4-1-150x115.png" alt="" style="border:1px solid gray;width:190px;">
				</div>
				<div class="col-lg-2 col-sm-4 col-xs-12" style="padding:0px;margin:0px;">
					<img src="images/testimonials/5-1-150x115.png" alt="" style="border:1px solid gray;width:190px;">
				</div>
				<div class="col-lg-2 col-sm-4 col-xs-12" style="padding:0px;margin:0px;">
					<img src="images/testimonials/6-1-150x115.png" alt="" style="border:1px solid gray;width:190px;">
				</div>
				<div class="col-lg-2 col-sm-4 col-xs-12" style="padding:0px;margin:0px;">
					<img src="images/testimonials/2-1-150x115.png" alt="" style="border:1px solid gray;width:190px;">
				</div>	

			</div>
			</div>
		
		</section>
	</main>  
	<!--Footer : Begin-->
	<footer-section> </footer-section>
	<div id="sitebodyoverlay"></div>
	<nav id="mb-main-menu" class="main-menu" style="display:none;">
		<div class="mb-menu-title">
			<h3>Navigation</h3>
			<span id="close-mb-menu">
				<i class="fa fa-times-circle"></i>
			</span>
		</div>
		<ul  class="cate_list">
			<li class="level0 parent col1 all-product">
				<a href="#">
					<span>All Product</span>
					<i class="fa fa-chevron-down"></i><i class="fa fa-chevron-right"></i>
				</a>
				<ul class="level0">
					<li class="level1">
						<a href="#" title="Business Card">Business Card</a>
					</li>
					<li class="level1">
						<a href="#" title="Premium Business Card">Premium Business Card</a>
					</li>
					<li class="level1">
						<a href="#" title="Free Business Card">Free Business Card</a>
					</li>
					<li class="level1">
						<a href="#" title="Marketing Materials">Marketing Materials</a>
					</li>
					<li class="level1">
						<a href="#" title="Dance Marketing Kit">Dance Marketing Kit</a>
					</li>
					<li class="level1 view-all-pro">
						<a href="#" title="view all product">View all</a>
					</li>
				</ul>
			</li>
			<li class="level0 parent col1">
				<a href="#" title="Business Cards">
					<span>Business Cards</span>
					<i class="fa fa-chevron-down"></i><i class="fa fa-chevron-right"></i>
				</a>
				<ul class="level0">
					<li class="level1 nav-1-1 first item">
						<a href="#" title="Premium Business Cards">Premium Business Cards</a>
					</li>
					<li class="level1 nav-1-2 item">
						<a href="#" title="Free Business Cards">Free Business Cards</a>
					</li>
					<li class="level1 nav-1-3 item">
						<a href="#" title="Die-Cut Business Cards">Die-Cut Business Cards</a>
					</li>
					<li class="level1 nav-1-4 item">
						<a href="#" title="Standard Business Cards">Standard Business Cards</a>
					</li>
					<li class="level1 nav-1-5 item">
						<a href="#" class="Personal Business Cards">Personal Business Cards</a>
					</li>
					<li class="level1 nav-1-6 item">
						<a href="#" title="Business Card Holders">Business Card Holders</a>
					</li>
					<li class="level1 nav-1-7 item">
						<a href="#" title="Networking Cards">Networking Cards</a>
					</li>
					<li class="level1 nav-1-8 item">
						<a href="#" title="Appointment Cards">Appointment Cards</a>
					</li>
					<li class="level1 nav-1-9 last item">
						<a href="#" title="Mommy Cards">Mommy Cards</a>
					</li>
				</ul>
			</li>
			<li class="level0">
				<a href="/category" title="Marketing">Marketing</a>
			</li>
			<li class="level0">
				<a href="#" title="Postcards">Postcards</a>
			</li>
			<li class="level0">
				<a href="#" title="Stickers & Badges">Stickers & Badges</a>
			</li>
			<li class="level0" title="Promotional">
				<a href="#">Promotional</a>
			</li>
		</ul>
	</nav> 

  </div>
</template>

<script>
// @ is an alias to /src
import 'animate.css';
import NavBarHome from '@/components/NavBarHome.vue'
import FooterSection from '@/components/FooterSection.vue'
import MMenu from '@/views/MMenu.vue'
import {mapState} from 'vuex';
export default {
  name: 'Home',
  components: {
    FooterSection,NavBarHome,MMenu
  },
  data(){
	return {
		slideShow:null,
		ready:false,
		items:["animate__backInDown","animate__backInLeft", "animate__backInRight","animate__backInUp"],
		someLocalProperty:"/"
	}
  },
  computed:{
	...mapState(['homeSlider','mainProducts']),
	anyClass(){
		var item = this.items[Math.floor(Math.random()*this.items.length)];
		return item
	},
	
  },
  mounted(){
	this.checkSlider();
},
  methods:{
	checkSlider(){
		if (this.homeSlider.length) {
			this.setSlideShow()
		}else{
			setTimeout(() => {
				this.checkSlider()
			}, 500);
		}
	},
	setSlideShow(){
		if (this.homeSlider.length) {
			let content = ` <div class="slide-show" > <div class="vt-slideshow" > <ul>`;
			this.homeSlider.forEach((element, index) => {
				let ct = `
				<li class="slide${(index+1)}" data-transition="random" ><img src="images/slider/home/bg_slider_2.jpg" alt="" />
							<div class="tp-caption lfr" data-x="left"  data-hoffset="" data-y="170" data-start="800" data-speed="2000" data-endspeed="300"><span class="style1">
								<span class="textcolor">Flyers</span> & Leaflets</span></div> 
							<div class="tp-caption lfb" data-x="left"  data-hoffset="" data-y="225" data-start="800" data-speed="2000" data-endspeed="300">
								<span class="style2">
									Our A5 flyers and leaflets are our bestselling size.<br> 
									This is because they're perfect for potential prospects<br>  
									to carry around and are extremely cost effective. We<br> 
									currently have an offer of 1000 flyers and leaflets<br> 
									for only &pound; 24!
								</span>
							</div>
							<div class="tp-caption lfr" data-x="left" data-y="367" data-start="1300" data-speed="2000" data-easing="easeInOutQuint" data-endspeed="300"><a class="btn-sn" href="#">buy now</a></div> 
						</li>
				`
				content = content + ct
			});
			content = content + '</ul> </div> </div>'
			this.slideShow = content;
			this.ready = true
		}else{
			this.checkSlider()
		}
		// <div class="vt-slideshow" >
		// 			<ul>
		// 				<!-- <li v-for="(elm, index) in homeSlider" :key="elm.id" 
		// 				:class="['slide'+index+1]" data-transition="random" >
		// 					<img :src="getImgUrl(elm.image)" alt=""  />
		// 					<div :class="[elm.txDirec]" :data-x="elm.tx"  data-hoffset="-56" data-y="170" data-start="800" data-speed="2000" data-endspeed="300"><span class="style1">
		// 						<span class="textcolor"> {{ elm.title }} </span></span></div> 
		// 					<div :class="[elm.txDirec]" :data-x="elm.tx"  data-hoffset="-56" data-y="225" data-start="800" data-speed="2000" data-endspeed="300"
		// 					>
		// 						<span class="style2" v-html="elm.desc">
								
		// 						</span>
		// 					</div>
		// 				</li> -->
		// 				<li class="slide1" data-transition="random" ><img src="images/slider/home/bg_slider_2.jpg" alt="" />
		// 					<div class="tp-caption lfr" data-x="left"  data-hoffset="" data-y="170" data-start="800" data-speed="2000" data-endspeed="300"><span class="style1">
		// 						<span class="textcolor">Flyers</span> & Leaflets</span></div> 
		// 					<div class="tp-caption lfb" data-x="left"  data-hoffset="" data-y="225" data-start="800" data-speed="2000" data-endspeed="300">
		// 						<span class="style2">
		// 							Our A5 flyers and leaflets are our bestselling size.<br> 
		// 							This is because they're perfect for potential prospects<br>  
		// 							to carry around and are extremely cost effective. We<br> 
		// 							currently have an offer of 1000 flyers and leaflets<br> 
		// 							for only &pound; 24!
		// 						</span>
		// 					</div>
		// 					<div class="tp-caption lfr" data-x="left" data-y="367" data-start="1300" data-speed="2000" data-easing="easeInOutQuint" data-endspeed="300"><a class="btn-sn" href="#">buy now</a></div> 
		// 				</li>
		// 				<li class="slide2" data-transition="random" ><img src="images/slider/home/bg_slider_3.jpg" alt="" />
		// 					<div class="tp-caption lft" data-x="left"  data-hoffset="" data-y="170" data-start="800" data-speed="2000" data-endspeed="300"><span class="style1"><span class="textcolor">Flyers</span> & Leaflets</span></div> 
		// 					<div class="tp-caption lfb" data-x="left"  data-hoffset="" data-y="225" data-start="800" data-speed="2000" data-endspeed="300">
		// 						<span class="style2">
		// 							Our A5 flyers and leaflets are our bestselling size.<br> 
		// 							This is because they're perfect for potential prospects<br>  
		// 							to carry around and are extremely cost effective. We<br> 
		// 							currently have an offer of 1000 flyers and leaflets<br> 
		// 							for only &pound; 24!
		// 						</span>
		// 					</div>
		// 					<a class="btn-sn" href="#">buy now</a></div> 
		// 				</li>
		// 			</ul> 
		// 		</div>
	},
	gtImgUrlBig(pet){
		var images = require.context('../../public/images/product/', false, /\.jpg$/)
    return images('./' + pet)
	},
	getImgUrl(pet) {
    var images = require.context('../../public/images/slider/home/', false, /\.jpg$/)
    return images('./' + pet)
  },
  getImgUrlPro(pet) {
    var images = require.context('../../public/images/product/263x263/', false, /\.jpg$/)
    return images('./' + pet)
  },
},

}
</script>
<style>
.VueCarousel-wrapper{

	max-width: 1351px !important;
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
}
.slider-title-left{
    font-size: 3vw;
}
.slider-sub-left{
font-size:1.5vw;
}
.slide-left-cont{
	left: 44px;
    position: absolute;
    top: 177px;
}
.slider-title-right{
    font-size: 3vw;
}
.slider-sub-right{
font-size:1.5vw;
}
.slide-right-cont{
	right: 44px;
    position: absolute;
    top: 177px;
}
.slide-cont{
	position:relative;height:auto;
}
.slide-cont h2 {
	color:#fd5b4e;
}
.ttttt{
	padding:61px 0px 61px 369px;
}
.tttttSmall{
	padding:61px 0px 61px 0px;
}
.smallSlider{
	width: 300px;
	margin-left: auto;
	margin-right: auto;
}
.smallSlider * {
	text-align: center;
	margin-top:8px;
	/* font-size:11vw; */
}
.smallSlider h2 {font-size: 8vw;}
.smallSlider h4 {font-size: 3vw;}
.smallSlider a {
	display: block;
    width: 115px;
    margin-left: auto;
    margin-right: auto;
}
</style>