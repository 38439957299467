import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta:{auth:false, auths: false, title:"VestiGo - Home Page"}
  },
  {
    path: '/about-us',
    name: 'About',
    meta:{auth:false, auths: false,title:"VestiGo - About Us"},
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutUs.vue')
  },
  {
    path: '/contact-us',
    name: 'Contact',
    meta:{auth:false, auths: false,title:"VestiGo - Contact Us"},
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactUs.vue')
  },
  {
    path: '/user-dashboard',
    name: 'UserDashboard',
    meta:{auth:true, auths: false,title:"VestiGo - User Area"},
    component: () => import(/* webpackChunkName: "about" */ '../views/Dashboard.vue')
  },
  {
    path: '/user-dashboard/refs',
    name: 'RefsDashboard',
    meta:{auth:false, auths: false,title:"VestiGo - Referrals Area"},
    component: () => import(/* webpackChunkName: "about" */ '../views/Refs.vue')
  },
  {
    path: '/user-dashboard/invoices',
    name: 'Invoices',
    meta:{auth:true, auths: false,title:"VestiGo - Invoices"},
    component: () => import(/* webpackChunkName: "about" */ '../views/Invoices.vue')
  },
  {
    path: '/user-dashboard/quotes',
    name: 'Quotes',
    meta:{auth:true, auths: false,title:"VestiGo - Quotes"},
    component: () => import(/* webpackChunkName: "about" */ '../views/Quotes.vue')
  },
  {
    path: '/supplier/:item/:order',
    name: 'Suplier',
    meta:{auth:false, auths: false,title:"VestiGo - Quotes"},
    component: () => import(/* webpackChunkName: "about" */ '../views/Supplier.vue')
  },
  {
    path: '/user-dashboard/jobs',
    name: 'Jobs',
    meta:{auth:true, auths: false,title:"VestiGo - Jobs"},
    component: () => import(/* webpackChunkName: "about" */ '../views/Jobs.vue')
  },
  {
    path: '/user-dashboard/jobs/:id',
    name: 'Jobs',
    meta:{auth:true, auths: false,title:"VestiGo - Jobs"},
    component: () => import(/* webpackChunkName: "about" */ '../views/Jobs.vue')
  },
  {
    path: '/cart',
    name: 'Cart',
    meta:{auth:true, auths: false,title:"VestiGo - Cart"},
    component: () => import(/* webpackChunkName: "about" */ '../views/Cart.vue')
  },
  {
    path: '/checkout',
    name: 'Checkout',
    meta:{auth:true, auths: false,title:"VestiGo - Checkout"},
    component: () => import(/* webpackChunkName: "about" */ '../views/Checkout.vue')
  },
  {
    path: '/user-dashboard/addresses',
    name: 'Addresses',
    meta:{auth:true, auths: false,title:"VestiGo - Addresses"},
    component: () => import(/* webpackChunkName: "about" */ '../views/Addresses.vue')
  },
  {
    path: '/login',
    name: 'Login',
    meta:{auth:false, auths: true,title:"VestiGo - Login"},
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    meta:{auth:false, auths: true,title:"VestiGo - Register"},
    component: () => import(/* webpackChunkName: "about" */ '../views/Register.vue')
  },
  {
    path: '/services',
    name: 'Services',
    meta:{auth:false, auths: false,title:"VestiGo - Services"},
    component: () => import(/* webpackChunkName: "about" */ '../views/Services.vue')
  },
  {
    path: '/how-to-order-online',
    name: 'HowToOrderOnline',
    meta:{auth:false, auths: false,title:"VestiGo - How To Order Online"},
    component: () => import(/* webpackChunkName: "about" */ '../views/HowToOrder.vue')
  },
  {
    path: '/artwork-guide',
    name: 'ArtworkGuide',
    meta:{auth:false, auths: false,title:"VestiGo - Artwork Guide"},
    component: () => import(/* webpackChunkName: "about" */ '../views/ArtworkGuide.vue')
  },
  {
    path: '/product/:product',
    name: 'Details',
    meta:{auth:false, auths: false,title:"VestiGo - Product"},
    component: () => import(/* webpackChunkName: "about" */ '../views/Details.vue')
  },
  {
    path: '/custom-product/:id',
    name: 'CustomProduct',
    meta:{auth:false, auths: false,title:"VestiGo - Custom Product"},
    component: () => import(/* webpackChunkName: "about" */ '../views/Custom.vue')
  },
  {
    path: '/single-product/:id',
    name: 'SingleProduct',
    meta:{auth:false, auths: false,title:"VestiGo - Single Product"},
    component: () => import(/* webpackChunkName: "about" */ '../views/SingleProduct.vue')
  },
  {
    path: '/category/:category',
    name: 'Category',
    meta:{auth:false, auths: false,title:"VestiGo - Category"},
    component: () => import(/* webpackChunkName: "about" */ '../views/Category.vue')
  },
  {
    path: '/verify/:email/:code',
    name: 'Verify',
    meta:{auth:false, auths: false,title:"VestiGo - Verify"},
    component: () => import(/* webpackChunkName: "about" */ '../views/Verify.vue')
  },
  {
    path: '/user-dashboard/users',
    name: 'Users',
    meta:{auth:true, auths: false,title:"VestiGo - Users"},
    component: () => import(/* webpackChunkName: "about" */ '../views/Users.vue')
  },
  {
    path: '/user-dashboard/add-users',
    name: 'AddUser',
    meta:{auth:true, auths: false,title:"VestiGo - Users"},
    component: () => import(/* webpackChunkName: "about" */ '../views/AddUser.vue')
  },
  {
    path: '/custom-product/re-order/:id',
    name: 'Reorder',
    meta:{auth:false, auths: false,title:"VestiGo - Reorder Product"},
    component: () => import(/* webpackChunkName: "about" */ '../views/Reorder.vue')
  },
  {
    path: '/quotes-form',
    name: 'Quotes',
    meta:{auth:true, auths: false,title:"VestiGo - Quotes"},
    component: () => import(/* webpackChunkName: "about" */ '../views/QuotesForm.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  let user = localStorage.getItem("VestigoUser")
  if (user) {
    if (to.meta.auths) {
      next({name:"UserDashboard"})
    }else{
      next()
    }
  }else{
    if (!to.meta.auth) {
      next()
    }else{
      next({name:"Login"})
    }
  }
});
router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
      document.title = to.meta.title;
  });
});
export default router
