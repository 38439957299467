import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '../public/template/css/style-main.css'
import VueCarousel from 'vue-carousel';
Vue.use(require('vue-moment'));
Vue.use(VueCarousel);
Vue.config.productionTip = false
import GSignInButton from 'vue-google-signin-button'
import GoogleAuth from './gauth'
Vue.prototype.$eventBus = new Vue(); // Global event bus
const gauthOption = {
  clientId: '854879043010-ghpc9bbj2usok37bv9jlm2bkmrn86t98.apps.googleusercontent.com',
  scope: 'profile email',
  prompt: 'select_account',
  plugin_name: "chat"
}
Vue.prototype.$LFT = (number) => {
  if (typeof number === 'string') {
    number = parseFloat(number);
  }

  // Check if the input is a valid number
  if (isNaN(number)) {
    throw new Error('Input must be a number or a string representing a number');
  }

  // Convert the number to fixed 2 decimal places
  const formattedNumber = number.toFixed(2);

  // Use toLocaleString to format the number with locale-specific separators
  let dd = parseFloat(formattedNumber).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  const valuedNumber = parseFloat(dd.replace(/,/g, ''));
  return Number(valuedNumber).toFixed(2)

}

Vue.use(GoogleAuth, gauthOption)
Vue.use(GSignInButton)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
