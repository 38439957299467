<template>
	<div>

  <footer>
		<div class="footer-main" style="background:#161616;position:relative;">
			<div class="container">
				<div class="row">
					<div class="col-md-3 col-sm-4 col-xs-12 about-us footer-col">
						<h2>About Us</h2>
						<div class="footer-content">
							<a href="index.html" title="Cmsmart logo footer" class="logo-footer">
								<img src="/images/logo.png" alt="logo footer">
							</a>
							<ul class="info">
								<li>
									<i class="fa fa-home"></i>
									<span>102580 Santa Monica Los Angeles</span>
								</li>
								<li>
									<i class="fa fa-phone"></i>
									<span>+3 045 224 33 12</span>
								</li>
								<li>
									<i class="fa fa-envelope-o"></i>
									<span><a href="mailto:support@netbaseteam.net" title="send mail to Cmsmart">support@netbaseteam.net</a></span>
								</li>
							</ul>
							<ul class="footer-social">
								<li>
									<a href="#" title="Facebook">
										<i class="fa fa-facebook"></i>
									</a>
								</li>
								<li>
									<a href="#" title="Twiter">
										<i class="fa fa-twitter"></i>
									</a>
								</li>
								<li>
									<a href="#" title="Google plus">
										<i class="fa fa-google-plus"></i>
									</a>
								</li>
							</ul>
						</div>
					</div>
					<div class="col-md-3 col-sm-4 col-xs-12 corporate footer-col">
						<h2>Corporate</h2>
						<div class="footer-content">
							<ul>
								<li>
									<a href="/about-us" title="About us">About</a>
								</li>
								<li>
									<a href="cart.html" title="Green">Green</a>
								</li>
								<li>
									<a href="checkout.html" title="Afiliates">Afiliates</a>
								</li>
								<li>
									<a href="/contact-us" title="Non-profits and Government">Non-profits and Government</a>
								</li>
								<!-- <li>
									<a href="detail.html" title="Terms of Service">Terms of Service</a>
								</li>
								<li>
									<a href="faq.html" title="Privacy Policy">Privacy Policy</a>
								</li> -->
								<li>
									<a href="/how-to-order-online" title="How To Order Online">How To Order Online</a>
								</li>
								<li>
									<a href="/artwork-guide" title="Artwork Guide">Artwork Guide</a>
								</li>
							</ul>
						</div>
					</div>
					<div class="col-md-3 col-sm-4 col-xs-12 support footer-col">
						<h2>Support</h2>
						<div class="footer-content">
							<ul>
								<li>
									<a href="myaccount-information.html" title="My Account">My Account</a>
								</li>
								<li>
									<a href="#" title="Design Guide">Design Guide</a>
								</li>
								<li>
									<a href="faq.html" title="FAQ">FAQ</a>
								</li>
								<li>
									<a href="#" title="Design Service">Design Services</a>
								</li>
								<li>
									<a href="/contact-us" title="Contact Us">Contact Us</a>
								</li>
							</ul>
						</div>
					</div>
					<div class="col-md-3 col-xs-12 other-info footer-col hidden-sm">
						<h2>Other Info</h2>
						<div class="footer-content">
							<p>
								Printmart provides fast online printing for both homes and businesses. We  provide high quality business cards, postcards, flyers, brochures, stationery and  other premium online print products.
							</p>
							<img src="/images/footer-payment.png" alt="Payment method">
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="footer-bottom">
			<div class="container">
				<div class="row">
					<div class="col-md-12">
						<p class="copy-right">Central - Copyright © <a href="http://netbaseteam.com/" title="Cmsmart - Magento theme">netbaseteam.com</a>. All Rights Reserved</p>
						<a href="#" id="back-to-top">
							<i class="fa fa-chevron-up"></i>
							Top
						</a>
					</div>
				</div>
			</div>
		</div>
	
	</footer>
	<div id="sitebodyoverlay"></div>
	<nav id="mb-main-menu" class="main-menu" v-if="$store.state.navMenu">
		<div class="mb-menu-title">
			<h3>Navigation</h3>
			<span id="close-mb-menu" @click="$store.state.navMenu = !$store.state.navMenu">
				<i class="fa fa-times-circle"></i>
			</span>
		</div>
		<ul  class="cate_list">
			<!-- <li class="level0 parent col1 all-product">
				<a href="/about-us">
					<span>About Us</span>
					<i class="fa fa-chevron-down"></i><i class="fa fa-chevron-right"></i>
				</a>
			
			</li>
			<li class="level0 parent col1">
				<a href="/services" title="Business Cards">
					<span>Services</span>
					<i class="fa fa-chevron-down"></i><i class="fa fa-chevron-right"></i>
				</a>
				
			</li> -->
			<li class="level0">
				<a href="/services" title="Marketing">Services</a>
			</li>
			<li class="level0">
				<a href="/about-us" title="Marketing">About Us</a>
			</li>
			<li class="level0">
				<a href="/contact-us" title="Marketing">Contact Us</a>
			</li>
			<!-- <li class="level0">
				<a href="#" title="Postcards">Postcards</a>
			</li>
			<li class="level0">
				<a href="#" title="Stickers & Badges">Stickers & Badges</a>
			</li>
			<li class="level0" title="Promotional">
				<a href="#">Promotional</a>
			</li> -->
		</ul>
	</nav> 
</div>
</template>

<script>
export default {

}
</script>

<style>

</style>